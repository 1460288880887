@import "uikit/uikit.less";
@import "uikit/components/autocomplete.less";
@import "uikit/components/datepicker.less";
@import "uikit/components/form-advanced.less";
@import "style.less";
@import "formValidation.less";
@import "theme.less";
@import "custom.less";

@gt-md-gold: #a4925a;
@gt-gold: #b3a369;

html{
  background-color: #ffffff;
}
body{
  overflow-x: hidden;
}

.panel-header-primary
{
  background-color: @gt-gold;
  padding: 15px;
  color: white;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 24px;
}

.panel-header-secondary
{
  background-color: @gt-gold;
  padding: 15px;
  color: white;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 24px;
}

.why-give{
  padding-left: 15px;
}

.italic
{
  font-style: italic;
}



.be-top-1>div{
  margin-top: 10px;
  text-align: center;
}


.be-content>div{
  padding: 50px;
}


.be-top-1
{
  padding-top: 5px;
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #545454;
}

.be-top-2
{
  color: white;
  width: 100%;
  background: linear-gradient(to right, @gt-md-gold 0%, @gt-md-gold 45%, @gt-gold 55%, @gt-gold 100%);
}

.be-top-3 {
  padding: 0;
  height: 37px;
}
.be-top-3-1
{
  //width:55%;
  background-color: #eeb211;
  height: 37px;
  position: relative;
  z-index: 50;
}

.be-top-3-2
{
  //width:48%;
  background-color: #e6e6e6;
  height: 37px;
  position: relative;
  z-index: 50;
}

.be-top-3-3
{
  //width:100%;
  background-color: #a9aaae;
  height: 37px;
  position: relative;
  z-index: 30;
}

.be-bottom{
  background-color: @gt-md-gold;
  height: 100%;
  margin-top: 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 13px;
  color: black;
  a{
    color: black;
    font-weight: bold;
  }
  .uk-subnav > * > :hover{
    color: black;
    text-decoration: underline;
  }

}
.be-bottom2{
  height: 22px;
  max-width: 100%;
  background-color: #BFB37C;
  padding: 0;
  margin-top: 5%;
}

.icon-logo-header{
  height: 100px;
  width: 334px;
  margin-left: 16%;
  padding-bottom: 10px;
}

.text-top{
  color: #eeb211;
  float: right;
  width: 400px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 20px;
  position: absolute;
  margin: 2% 0 0 2%;
}

.text-header-top{
  color: black;
  float: right;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin: 2% 0 0 0;
}

.div-footer{
  box-sizing: border-box;
}

.footer-p{
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  line-height: 1.1;
  margin: 0 0 6px 0;
  letter-spacing: .025em;

}

.link-footer{
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 0;
}

.link-footer-1{
  margin-bottom: 0;
  margin-top: 20px;
}

.icon-logo-footer{
  border:0;
  width: 140px;
  height: auto;
  max-width: 100%;
}




.butt-form{
  line-height: 44px;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 5%;
  background-color: @gt-md-gold ;
  color: black;

  font-family: "Roboto",sans-serif;
  font-weight: 500;
  font-size: 16px;
}

#introduction{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 22px;
  text-align : justify;
  line-height: 33px;
}

.text-donation{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.why-give{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.name{
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 20px;
}

.legende{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 13px;
}

.info{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.label{
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.pannel-img{
  width: 150px;
  height: 150px;
}

.uk-button-primary{
  background-color: @gt-md-gold !important;
  &:hover{
    background-color: @gt-md-gold 75% !important;
  }
}


/*
 * Backend Layout
 */

/* Wrapper */

.be-wrapper:not(.be-grid-preserve)
{
	margin-left: 0;
}

.be-wrapper:not(.be-grid-preserve) > *
{
	padding-left: 0;
}

.be-wrapper
{
	max-width: 100vw;
	min-height: 100vh;
}

.be-wrapper > div
{
	min-height: 100vh;
	padding-left: 0;
}

/* Header */

.be-header h1
{
	margin-bottom: 0;
}

.be-header h2
{
	color: #569aca;
	font-weight: normal;
}

/* Toolbar */

.be-toolbar .uk-subnav > *
{
	margin-top: 15px;
	line-height: 35px;
}

.be-toolbar .uk-subnav-line > *:nth-child(n+2)::before
{
	border-left: none;
}

.be-toolbar .uk-subnav .be-userinfo
{
	font-style: italic;
}

/* Sidebar */

.be-sidebar-wrapper::after
{
	background: #ffffff;
	bottom: 0;
	content: "";
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	width: 1px;
}

.be-sidebar-wrapper
{
	background: #ffffff;
	width: 100px;
	position: relative;
	text-align: center;
}

.be-sidebar-wrapper .be-sidebar-menu-container
{
	padding: 30px 0;
	text-align: center;
	z-index: 1002;
}

.be-sidebar-wrapper .be-sidebar-menu-container .be-sidebar-logo
{
	display: inline-block;
	margin: 0 auto 60px;
}

.be-sidebar-wrapper .be-sidebar-menu-container .be-sidebar-nav
{
	margin-bottom: 40px;
}

/* Content */

.be-content-wrapper
{
	width: calc(100vw - 100px);
	position: relative;
}

.be-content-wrapper .be-content-container
{
	box-sizing: border-box;
	padding: 15px 30px;
}

/* Main */

.be-main
{
	min-height: calc(100vh - 175px);
	position: relative;
}

/* - Subnav */

.be-subnav .uk-subnav-line > *:nth-child(n+2)::before
{
	border-left: none;
}

.be-subnav a > i
{
	padding-right: 5px;
}

/* - Search and filter */

.be-search-n-filter a > i
{
	padding-right: 5px;
}

/* - Form */

label
{
	font-weight: 600;
}

.be-required label
{
	color: #569aca;
	font-weight: bold;
}

.be-note
{
	font-size: 14px;
	font-style: italic;
}

/* Bottom */

.be-bottom
{
	margin-top: 30px;
}

.be-copyright
{
	font-size: 12px;
}


/* Main subnav menu */

.be-sidebar-subnav
{
	position: absolute;
	background: #569aca;
	display: block;
	top: 0;
	left: 100px;
	width: 305px;
	padding: 40px 30px;
	height: 100vh;
	text-align: left;
	z-index: 2;
}

.be-sidebar-subnav h2
{
	color: #ffffff;
	font-size: 30px;
	padding-bottom: 50px;
	margin-bottom: 15px;
	border-bottom: 1px solid #0a2d45;
}

/* - navbar items */

.be-sidebar-subnav .uk-nav-navbar > li > a
{
	color: #ffffff;
}

.be-sidebar-subnav .uk-nav-navbar > li >  a > i
{
	display: block;
	float: left;
	width: 25px;
	line-height: 20px;
}

.be-sidebar-subnav .uk-nav-navbar > li >  a > span
{
	display: block;
	margin-left: 25px;
	line-height: 20px;
}

/* - Accordion */

.be-sidebar-subnav h3.uk-accordion-title
{
	background: none;
	margin-bottom: 15px;
	margin-top: 15px;
	padding: 0;
	color: #0a2d45;
	font-size: 18px;
	line-height: 30px;
	border: none;
	border-radius: 0;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title:first-child
{
	margin-top: 0;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title::after
{
	content: '\f067';
	background: #0a2d45;
	display: block;
	float: right;
	width: 30px;
	height: 30px;
	font-family: 'FontAwesome';
	font-size: 14px;
	color: #ffffff;
	line-height: 30px;
	text-align: center;
	border-radius: 3px;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title.uk-active::after
{
	content: '\f068';
}

.be-sidebar-subnav .uk-accordion-content
{
	padding: 0;
}


/*
 * UIKit overrides
 */
 
/* Tabs */

.uk-tab > li > a {
	background: #494b4d;
}

.uk-tab > li.uk-disabled > a {
	background: #d2d4d3;
}

.uk-tab > li.uk-disabled > a:hover,
.uk-tab > li.uk-disabled > a:focus {
	background: #d2d4d3;
}

/* Main navbar */

.uk-navbar-nav:first-child > li:first-child > a {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.uk-navbar-nav > li > a {
	border-width: 0 0 1px 0;
	border-color: #e5e5e5;
	color: #a0a2ab;
	font-family: "Cabin",Helvetica,Arial,sans-serif;
	font-size: 36px;
	font-weight: normal;
	width: 100px;
	height: 95px;
	line-height: 95px;
	margin-left: 0;
	margin-top: 0;
	padding: 0;
}

.uk-navbar-nav > li:first-child > a {
	border-width: 1px 0 1px 0;
}

.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a.focus,
.uk-navbar-nav > li.uk-open > a,
.uk-navbar-nav > li.uk-active > a {
	background: #569aca;
	color: #ffffff;
}

/* Tables */

.uk-table
{
	margin-top: 20px;
}

.uk-table th
{
	background: #569aca;
	color: #ffffff;
	border-left: 1px solid #ebedec;
}

.uk-table th:first-child
{
	border-radius: 3px 0 0 0;
	border-left: none;
}

.uk-table th:last-child
{
	border-radius: 0 3px;
}

/* Forms */

.uk-form-select
{
	background: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	color: #494b4d;
	max-width: 100%;
	line-height: 30px;
}

.uk-form-select select
{
	background: none;
	border: none;
	border-radius: 0;
}

.uk-form-select span
{
	padding: 0 5px;
}

.uk-form-horizontal .uk-width-1-3 .uk-form-label {
	width: 100px !important;
}

.uk-form-horizontal .uk-width-1-3 .uk-form-controls {
	margin-left: 115px !important;
}